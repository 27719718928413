import Home from './screens/home/Home'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import ITServices from './screens/itservices/ITServices';
import Travel from './screens/travel/Travel';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path='/' component={Home}></Route>
          <Route exact path='/itservices' component={ITServices}></Route>
          <Route exact path='/travel' component={Travel}></Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
