import React from 'react';
import Footer from '../component/Footer';
import Header from '../component/Header';
import Carousels from '../component/Carousels';
import Aboutus from '../component/Aboutus';
import Main from '../component/Main';
import Contact from '../component/Contact';
import Topbar from '../component/Topbar';

export default function ITServices() {
    return (
        <div>
            <Carousels screen="itservices" />
            <Topbar/>
            <Header screen="itservices" />
            <Aboutus screen="itservices" />
            <Main screen="itservices" />
            <Contact screen="itservices" />
            <Footer />
        </div>
    )
}
