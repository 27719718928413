import React from 'react';
import Particles from 'react-particles-js';
import './Home.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom'; 

export default function Home() {
    return (
        <>
            <Particles params={{
                particles: {
                    number: {
                        value: 150,
                        density: {
                            enable: true,
                            value_area: 1000,
                        }
                    },
                },
            }} />

            <div className="container elementor-widget-wrap">
                <div className="navigation-right">
                    <i className="icofont-phone" /> +91-987-385-0510<i className="icofont-clock-time icofont-rotate-180" /> Mon-Sat: Opening at 10:00 AM
                </div>

                <div className="elementor-image">
                    <img src={process.env.PUBLIC_URL + '/assets/img/happify_India_logo.png'} alt="" />
                </div>

                <div className="row elementor-heading">
                    <h2 className="fadeInDown" data-text='We Build Smiles...'>We Build Smiles...</h2>
                </div>

                <div className="row elementor-box">
                    <div className="col-md-6 elementor-box-one">
                        <Link to="/itservices"> <img src={process.env.PUBLIC_URL + '/assets/img/hero-it-img.png'} alt="" />
                            <h3 className="elementor-fadeInDown">IT Services</h3>
                        </Link>
                    </div>
                    <div className="col-md-6 elementor-box-two">
                        <Link to="/travel"> <img src={process.env.PUBLIC_URL + '/assets/img/hero-tour-img.png'} alt="" />
                            <h3 className="elementor-fadeInDown">Tour & Travels</h3>
                        </Link>
                    </div>
                </div>

                <div className="social-links">
                    <a href="https://twitter.com/happifyindia" target="_blank" without rel="noreferrer" className="twitter"><i className="bx bxl-twitter" /></a>
                    <a href="https://www.facebook.com/Happifylndia/" target="_blank" without rel="noreferrer" className="facebook"><i className="bx bxl-facebook" /></a>
                    <a href="https://www.instagram.com/happifyindia/" target="_blank" without rel="noreferrer" className="instagram"><i className="bx bxl-instagram" /></a>
                    <a href="https://www.kooapp.com/profile/happifyindia" target="_blank" without rel="noreferrer" className="google-plus"><img src="assets/img/koo.png" alt=""/></a>
                    <a href="https://www.linkedin.com/company/happify-india" target="_blank" without rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin" /></a>
                </div>

            </div>
        </>
    )
}
