import React from 'react'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

export default function PlanAndTheme() {
    return (
        <>
            <section id="plan-your-trip" className="plan-your-trip">
                <div className="container">
                    <div className="section-title">
                        <h2>Plan Your <span>Trip</span></h2>
                        <p>You can plan your trip for best services.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-beach-bed" /></h4>
                                    <span>Honeymoon</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-island" /></h4>
                                    <span>Family Holiday</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-people" /></h4>
                                    <span>Group	Trip</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-travelling" /></h4>
                                    <span>Business Trip</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gallery" className="gallery">
                <div className="container-fluid">
                    <div className="section-title">
                        <h2>Themes You Can<span> Explore</span></h2>
                        <p><Tabs defaultActiveKey="wildlife" id="uncontrolled-tab-example">
                            <Tab eventKey="wildlife" title={<span> <i className="icofont-bull" /> Wildlife </span>}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-5 mt-4 mt-lg-0">
                                            <img className="tab-left-image" src="assets/img/gallery/travel-gallery-7.jpg" alt="" />
                                        </div>
                                        <div className="col-lg-7 mt-4 mt-lg-0">
                                            <div className="box">
                                                <div class="box-content">The wildlife of India is diverse and its national parks	and	biosphere reserves are home	to many endangered wildlife species	like Red Panda and Royal Bengal Tigers,	the	vulnerable one-horned Rhinoceros and Snow Leopards, and critically endangered Great	Indian Bustard, among many other species of flora and fauna.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="heritage" title={<span> <i className="icofont-qq" /> Heritage</span>}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-5 mt-4 mt-lg-0">
                                            <img className="tab-left-image" src="assets/img/gallery/travel-gallery-2.jpg" alt="" />
                                        </div>
                                        <div className="col-lg-7 mt-4 mt-lg-0">
                                            <div className="box">
                                                <div class="box-content">India takes pride on its unsurpassed heritage;	eras over eras have influenced, moulded	and face lifted the rich heritage of which we	all are	part of. Distinctive edifices,	perennial culture and the determination	to keep	this incredibility have	preserved for us	an era no short	of marvels. With a startling	number of places and monuments enlisted	in	the list of Heritage Sites,India has made an indelible mark in world history.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Tab>
                            <Tab eventKey="pilgrimage" title={<span> <i className="icofont-alarm" /> Pilgrimage</span>}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-5 mt-4 mt-lg-0">
                                            <img className="tab-left-image" src="assets/img/gallery/travel-gallery-3.jpg" alt="" />
                                        </div>
                                        <div className="col-lg-7 mt-4 mt-lg-0">
                                            <div className="box">
                                                <div class="box-content">India	is	home	to	many	sacred	worship	places	spread	across	its	length	and	breadth	making	it
                                                a	top	pilgrimage	destination.	There	are	innumerable	Hindu	and	Gurudwaras, Jain	Temples,
                                                Churches,	Buddhist	Monasteries	and Mosques which	make	a	rare	sight	anywhere	else	in	the
world.	We	offer	the	best	pilgrimage	tours	in	India	that	assist	you	to	top	religious	sites.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </Tab>
                            <Tab eventKey="hillstation" title={<span> <i className="icofont-hill" /> Hill Station</span>}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-5 mt-4 mt-lg-0">
                                            <img className="tab-left-image" src="assets/img/gallery/travel-gallery-4.jpg" alt="" />
                                        </div>
                                        <div className="col-lg-7 mt-4 mt-lg-0">
                                            <div className="box">
                                                <div class="box-content">The	hill	stations	of	India	welcome	you	with	refreshing	vacations	for	your	friends	and	family.
                                                Dotted	with	wild	flowers	in	full	bloom,	bright	green	beauty, waterfalls and	unadulterated
                                                mountain	air,	all	these	hill	stations	present	a	pleasurable	experience	that	you	will	remember
                                                for	the	rest	of	your	life.	Apart	from	relishing	the	magical	creations	of nature	and
                                                scenic exquisiteness,	one	can	go	high	up	in	the	sky	through	mountaineering, trekking,
camping	and paragliding amidst misty	nature	woods.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="adventure" title={<span> <i className="icofont-camel-head" /> Adventure</span>}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-5 mt-4 mt-lg-0">
                                            <img className="tab-left-image" src="assets/img/gallery/travel-gallery-5.jpg" alt="" />
                                        </div>
                                        <div className="col-lg-7 mt-4 mt-lg-0">
                                            <div className="box">
                                                <div class="box-content">You	can’t	have	enough	adventure	in	India	since	the	country	offers	a	number	of	adventure
                                                experiences	that	can	leave	you	wanting	for	more.	The	great	Himalayas are	the	favourite
                                                haunt	of	every	adventure	lover	as	they	are	filled	with	opportunities of	finding	an	adventure
                                                memorable tour	of	your	choice.	So,	be	it	Jammu	&	Kashmir	or	Uttarakhand	or Leh Ladakh or Himachal Pradesh	or	even	Sikkim,	adventure	is	never	far	away	from	you.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tab>
                            <Tab eventKey="beach" title={<span> <i className="icofont-beach" /> Beach</span>}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-5 mt-4 mt-lg-0">
                                            <img className="tab-left-image" src="assets/img/gallery/travel-gallery-6.jpg" alt="" />
                                        </div>
                                        <div className="col-lg-7 mt-4 mt-lg-0">
                                            <div className="box">
                                                <div class="box-content">The	Indian	coastline	runs	from	Gujarat,	Maharashtra,	Goa,	Karnataka,	and	Kerala	in	the	west
                                                to	Tamil	Nadu	and	Andhra	Pradesh	in	south	to	Odisha	and	West	Bengal	in	the	east.	Not	to
                                                forget,	India	is	also	home	to	two	island	groups,	Andaman	&	Nicobar	Islands	and
Lakshadweep	making	it	an	irresistible	country	for	a	beach	vacation.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Tab>
                        </Tabs>
                        </p>
                    </div>
                </div>
            </section>

            <section id="service-we-offer" className="service-we-offer">
                <div className="container">
                    <div className="section-title">
                        <h2>Services we <span>offer</span></h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-hotel" /></h4>
                                    <span>Accommodation</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-5-star-hotel" /></h4>
                                    <span>Hotels</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-ui-flight" /></h4>
                                    <span>Flights</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-car" /></h4>
                                    <span>Car hire</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-bus" /></h4>
                                    <span>Volvo	Buses</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="member">
                                <div className="member-info">
                                    <h4><i className="icofont-travelling" /></h4>
                                    <span>Local	traveling</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
