import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import './Carousels.css';

export default function Carousels(props) {
  return (
    <Carousel id="home" >
      <Carousel.Item interval={900} keyboard={false} pauseOnHover={true}>
        <img src={props.screen === "itservices" ? "assets/img/slide/itservices-slide-1.jpg" : "assets/img/slide/travel-slide-1.jpg"} alt="" />
        <Carousel.Caption>
          <h3>{props.screen === "itservices" ? "We	Are" : "Places to visit by theme!"}</h3>
          <p>{props.screen === "itservices" ? "Technology	agnostic company	with	comprehensive	IT	capabilities." : "Traveling is all	about	what you like	and	when you like."}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img src={props.screen === "itservices" ? "assets/img/slide/itservices-slide-2.jpg" : "assets/img/slide/travel-slide-2.jpg"} alt="" />
        <Carousel.Caption>
          <h3>{props.screen === "itservices" ? "We	Help" : "Best for you!"}</h3>
          <p>{props.screen === "itservices" ? "Organizations	of	all	sizes	integrate technology	solutions	into	their	business." : "List	of recommended attractive and best travel plan for	you."}</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={props.screen === "itservices" ? "assets/img/slide/itservices-slide-3.jpg" : "assets/img/slide/travel-slide-3.jpg"} alt="" />
        <Carousel.Caption>
          <h3>{props.screen === "itservices" ? "Get	in	Touch" : "Let’s	Go,	Discover!"}</h3>
          <p>{props.screen === "itservices" ? "Would	you	like	to	discuss	your	new	project	with	our	technical	experts?" : "Varied culture, Amazing wildlife, Sandy beaches and Majestic Heritage."}</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}
