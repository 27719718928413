import React from 'react'

export default function Aboutus(props) {
    return (
        <>
            <section id="about" className="why-us">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <div className="box">
                                <h4>About us</h4>
                                <p>Happify India is structured in such a way to offer more acquirable results and solutions to you which headquartered in Delhi. </p>
                                <p>Happify India doing business across India engaged in Information Technologies and Tour & Travels services with certified, expert and professional team with experience of more than thousands of satisfied customers. </p>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <div className="box">
                                <h4>Our message</h4>
                                <p>Welcome to Happify India. We’re so happy you’re here! The concept is simple: our ultimate goal is to build smiles on your face through our services and make your life easy.</p>
                                <p>We help you in your IT related needs with our IT services and we help you to plan your dream vacation to spend quality time with your loved ones with our Tour & Travels services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
