import React from 'react'
import PlanAndTheme from './PlanAndTheme'

export default function Main(props) {

  return (
    <main id="main">
      <section id="service">
        {props.screen === "itservices" ? (<>
          <section id="chefs" className="chefs">
            <div className="container">
              <div className="section-title">
                <h2>Information	 <span>Technologies</span></h2>
                <p>We	are	experts	in	IT	primarily	working	in	Enterprise,	Healthcare	and	Education.	We	focus	on providing	technology	beyond	the	functionality	of	a	normal	working	computer	to	enhance the	productivity	of	your	business.</p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="member">
                    <div className="member-info">
                      <h4><i className="icofont-people" /></h4>
                      <span>Enterprise</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="member">
                    <div className="member-info">
                      <h4><i className="icofont-doctor" /></h4>
                      <span>Healthcare</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="member">
                    <div className="member-info">
                      <h4><i className="icofont-student" /></h4>
                      <span>Education</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="menu" className="menu">
            <div className="container">
              <div className="section-title-service">
                <h2>Our <span>Skills</span></h2>
              </div>
              <div className="row menu-container">
                <div className="col-lg-6 menu-item filter-starters">
                  <div className="menu-content">
                    <a href="##">User Services</a>
                  </div>
                  <div className="menu-ingredients">
                    Happify	India	provides	and	configures	the	latest	end-user	IT	products	from
                    Apple,	Dell,	Lenovo,	HP,	Epson	and	Microsoft	to	improve	our	customer
                    productivity,	reduce	IT	tasks	and	costs.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-salads">
                  <div className="menu-content">
                    <a href="##">Networking	Services</a>
                  </div>
                  <div className="menu-ingredients">
                    Happify	India	ensure	that	your	network	is	maintained	at	a	secure	level	we
                    would	recommend	combining	our	support	services	with	our	professional
                    services	so	we	can	proactively	support	your	network.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-datacenter">
                  <div className="menu-content">
                    <a href="##">Data Centre Services</a>
                  </div>
                  <div className="menu-ingredients">
                    Happify	India	help	business	to	protect	their	critical	system	and	data	with	our
                    backup,	disaster	recovery	and	business	continuity	solutions	and	services.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-managed">
                  <div className="menu-content">
                    <a href="##">Managed	Services</a>
                  </div>
                  <div className="menu-ingredients">
                    Happify	India	provides	product training	programs	that	support	individuals	and
                    enterprises.	We	monitor	and	manage	enterprise	infrastructure	services	offer
                    real-time	visibility	of	your	infrastructure	and	keep	it	up	and	running	with.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-professional">
                  <div className="menu-content">
                    <a href="##">Professional	Services</a>
                  </div>
                  <div className="menu-ingredients">
                    Happify	India	offer	a	complete	managed	solution	for	expert,	fast	and	proactive	IT	support	and	technical	services.
          </div>
                </div>
              </div>
            </div>
          </section>


          <section id="it-service" className="it-service">
            <div className="container">
              <div className="section-title">
                <h2>Services	we <span>offer</span></h2>
              </div>
              <div className="row it-service-container">

                <div className="col-lg-6 ">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Consultancy</a>
                    </div>
                    <div className="it-service-ingredients">
                      We	offer	consulting	services	which	will	assist	to	guide	you	on	the	best product/requirements	that	suits	the	needs	of	your	institution	because there	is	no	single
                      solution	to	suit	everyone.	Therefore,	we	offer	a	bespoke	consultation	at	every	stage	of	the
                      buying,	installation	and	support	processes	to	make	sure	you	get	exactly	what	you	need.
                  </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Radiology	solutions</a>
                    </div>
                    <div className="it-service-ingredients">
                      OsiriX	MD	is	a	world	famous	medical	imaging	viewer	for	Mac	(Apple).	OsiriX	Apple
                      computer-based	systems workstations	we can	help	you	to	deploy	and	integrate	OsiriX	in
                      your	environment,	including	configuring	a	DICOM	network,	PACS	server,	IT	support,	and
more.</div>
                  </div>
                </div>

                

                <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Deployment</a>
                    </div>
                    <div className="it-service-ingredients">
                      We	provide	nationwide	coverage	and	are	able	to	deploy	a	vast	number	of	devices	to	any
                      location	in	India.
          </div></div>
                </div>
               

                <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">B2B	Programme</a>
                    </div>
                    <div className="it-service-ingredients">
                      Corporate	purchase	for	all	IT devices.	Our	team	will	provide	the	best	business	practices	for
corporates	in	various	reward	&	gifting	activities.   </div>
                  </div></div>

                  
                <div className="col-lg-6">
                  <div className=" it-service-item">
                    <div className="it-service-content">
                      <a href="##">Support	&	Maintenance</a>
                    </div>
                    <div className="it-service-ingredients">
                      We	offer	custom	support	and	maintenance	packages	based	on	the	needs	of	the	institution. Support	like	pick-up/delivery	for	device	maintenance, repair	and technical	Support.        </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Solution	Design	&	Planning</a>
                    </div>
                    <div className="it-service-ingredients">
                      With	our	IT	consulting	services,	expert consultants	will	work	alongside	our	IT	Technical	leads
                      to	advice	clients	on	possible	solutions	based	on	requirements	gathered	and	design	a	project
plan	that	is	able	to	suit	the	needs	of	the	business       </div>
                  </div></div>

                  <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Training</a>
                    </div>
                    <div className="it-service-ingredients">
                      We provide	product	training	programs	that	support	individuals	and institution. We	offer
                      certified	training	programs	with	our	partners	who	can	provide	education	based pieces	of
training.</div></div>
                </div>

                <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Project	Delivery</a>
                    </div>
                    <div className="it-service-ingredients">
                      With	the	technical	plan	in	place,	we’ll	ensure	that	everything	follows	through	the	statement
                      of	works,	and	matches	best	practice	while	advising	your	in-house	staff	of	the	correct	and
proper	procedures.</div></div>
                </div>
                <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Data	Management</a>
                    </div>
                    <div className="it-service-ingredients">
                      We	offer	data	management services on	AWS	and	other Third	Party	cloud	based	services	as
                      per	your business	needs. We	help	businesses	to	protect	their	data	with	our	backup,	disaster
recovery	and	business	continuity	solutions. </div>
                  </div>
                </div>
               
                <div className="col-lg-6">
                  <div className="it-service-item">
                    <div className="it-service-content">
                      <a href="##">Mobility</a>
                    </div>
                    <div className="it-service-ingredients">
                      We	offer	customized application	development	&	mobility	solutions	which	caters	to	various
industries	and	business	needs. </div>
                  </div></div>

               


              </div>
            </div>
          </section>

        </>) : (<>
          <section id="events" className="events">
            <div className="container">
              <div className="section-title">
                <h2>Tour <span>&</span> Travel</h2>
                <p>
                  We	specialize	in	offering	customized	and	the	best	domestic and	international tour	packages
              to	our	clients.	We	always	attempt	to	exceed	the	expectations	of	our	clients	through complete	determination	and	commitment.</p>
              </div>
              <div className="row">

              </div>
            </div>
          </section>
          <section id="menu" className="menu">
            <div className="container">
              <div className="section-title-service">
                <h2>Why	Happify <span>India Trip?</span></h2>
              </div>
              <div className="row menu-container">
                <div className="col-lg-6 menu-item filter-starters">
                  <div className="menu-content">
                    <a href="##">Trusted Advisor</a>
                  </div>
                  <div className="menu-ingredients">
                    Our	dedicated	travel	team	diligently	works	round-the-clock	to	design	the	best	travel
                    experiences	for	the	customers.	The	skilled	team	spends	considerable	amounts	of	time
                    ideating	tour	packages	that	guarantee	to	make	travelling	with	us	an	experience	like	no
                    other.	We	select	the	finest	hotels	in	every	category,	boast	an	excellent	personal	fleet	of
                    vehicles	for	transportation.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-salads">
                  <div className="menu-content">
                    <a href="##">Responsive</a>
                  </div>
                  <div className="menu-ingredients">
                    Our	Holidays	are	co-created	with	our	customers,	ensuring	suggestions	and	feedback	are
                    basis	of	magical	holidays.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-datacenter">
                  <div className="menu-content">
                    <a href="##">Ease</a>
                  </div>
                  <div className="menu-ingredients">
                    Choose from	a	wide	range	of	over	a	thousand	holiday	and	experiences.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-managed">
                  <div className="menu-content">
                    <a href="##">Recognize	&	Connect</a>
                  </div>
                  <div className="menu-ingredients">
                    An	experienced	team	of	over	100+	travel	professionals	across	India	to	cater	to	your	travel
                    needs.
          </div>
                </div>
                <div className="col-lg-6 menu-item filter-professional">
                  <div className="menu-content">
                    <a href="##">Memorable	Experiences</a>
                  </div>
                  <div className="menu-ingredients">
                    Over	1000+	customers	have	created	their	most	memorable	experiences	with	us.
              </div>
                </div>
              </div>
            </div>
          </section>
          <PlanAndTheme />
        </>)}
      </section>

    </main>
  )
}
