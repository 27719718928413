import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

export default function Header(props) {
    const [header, setHeader] = useState("fixed-top d-flex align-items-center header-transparent");

    const listenScrollEvent = event => {
      if (window.scrollY < 300) {
        return setHeader("fixed-top d-flex align-items-center header-transparent");
      } else if (window.scrollY > 300) {
        return setHeader("fixed-top d-flex align-items-center");
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", listenScrollEvent);
      return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    return (
          <header id="header" className={header}>
            <div className="container d-flex align-items-center justify-content-between">
                <div className="logo">
                  <a href="/"><img src={process.env.PUBLIC_URL + '/assets/img/happify_India_logo.png'} alt="" /></a>
                </div>
                <div className="logo-right">
                {props.screen === "itservices" ? (<>
                                <Link to="/travel">
                                    <img className="back-to-services" src="../assets/img/backtoTravel.png" alt="Back to travel" />
                                </Link>
                            </>) : (<>
                                <Link to="/itservices">
                                    <img className="back-to-services" src="../assets/img/backtoITServices.png" alt="Back to IT Services" />
                                </Link>
                            </>)}
                 </div>
            </div>
        </header>
    )
}
