import React, { useState } from 'react'
import axios from 'axios'

export default function Contact(props) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isProgress, setIsProgress] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        setIsProgress(true);
        let name = firstName + " " + lastName;
        const userdetails = {
            name,
            phoneNumber,
            email,
            subject,
            message,
            isProgress
        }

        setFirstName('')
        setLastName('');
        setPhoneNumber('');
        setEmail('');
        setSubject('');
        setMessage('');

        console.log(userdetails);
        axios.post(`https://api.stepupitsolution.com/api/email`, { userdetails })
            .then(res => {
                console.log(res);
            })
        setIsProgress(false);
    }

    return (
        <>
            <section id="contact" className="contact">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Contact</span> Us</h2>
                        <p>Just send us your question or concern by sending a message and we will give you the help you need.</p>
                    </div>
                </div>
                <div className="container mt-5">
                    <div className="info-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 info">
                                <i className="icofont-google-map" />
                                <h4>Address</h4>
                                <p>Happify India<br />Head Office: B 98/12 Badarpur, New Delhi- 44</p>
                            </div>
                            {props.screen === "itservices" ? (<>
                                <div className="col-lg-4 col-md-6 info mt-4 mt-lg-0">
                                    <i className="icofont-computer" />
                                    <h4>IT Services</h4>
                                    <p>happifyindia@gmail.com<br />+91-987-385-0510</p>
                                </div>
                            </>) : (<>
                                <div className="col-lg-4 col-md-6 info mt-4 mt-lg-0">
                                    <i className="icofont-van" />
                                    <h4>Tour & Travels</h4>
                                    <p>travels.happifyindia@gmail.com<br />+91-770-396-2420</p>
                                </div>
                            </>)}
                            <div className="col-lg-4 col-md-6 info mt-4 mt-lg-0">
                                <i className="icofont-clock-time icofont-rotate-90" />
                                <h4>Open Hours</h4>
                                <p>Monday-Saturday:<br />Opening at 10:00</p>
                            </div>
                        </div>
                    </div>
                    <form method="post" className="php-email-form" onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <input type="text" name="firstname" className="form-control" id="firstname" required placeholder="First Name" data-rule="minlen:3" data-msg="Please enter at least 4 chars" value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)} />
                                <div className="validate" />
                            </div>
                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                <input type="text" name="lastname" className="form-control" id="lastname" required placeholder="Last Name" data-rule="minlen:3" data-msg="Please enter at least 4 chars" value={lastName}
                                    onChange={(e) => setLastName(e.target.value)} />
                                <div className="validate" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <input type="text" name="phone" className="form-control" id="phoneNumber" required placeholder="Your Phone umber" data-rule="minlen:4" data-msg="Please enter at least 4 chars" value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)} />
                                <div className="validate" />
                            </div>
                            <div className="col-md-6 form-group mt-3 mt-md-0">
                                <input type="email" className="form-control" name="email" id="email" required placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                <div className="validate" />
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" value={subject}
                                onChange={(e) => setSubject(e.target.value)} />
                            <div className="validate" />
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" name="message" rows={5} data-rule="required" data-msg="Please write something for us" placeholder="Message" defaultValue={""} value={message}
                                onChange={(e) => setMessage(e.target.value)} />
                            <div className="validate" />
                        </div>
                        <div>
                            {isProgress ? <div className="loading">Loading</div> : null}
                            <div className="error-message" />
                            <div className="sent-message">Your message has been sent. Thank you!</div>
                        </div>
                        <div className="text-center"><button type="submit">Send Message</button></div>
                    </form>

                </div>
            </section>
        </>
    )
}
