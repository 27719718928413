import React, { useState, useEffect } from "react";

export default function Topbar() {
    const [navbar, setNavbar] = useState("d-none d-lg-flex align-items-center fixed-top topbar-transparent");

    const listenScrollEvent = event => {
      if (window.scrollY < 300) {
        return setNavbar("d-none d-lg-flex align-items-center fixed-top topbar-transparent");
      } else if (window.scrollY > 300) {
        return setNavbar("d-none d-lg-flex align-items-center fixed-top");
      }
    };
  
    useEffect(() => {
      window.addEventListener("scroll", listenScrollEvent);
      return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    return (
            <section id="topbar" className={navbar}>
                <div className="container text-right">
                    <i className="icofont-phone" /> +91-987-385-0510<i className="icofont-clock-time icofont-rotate-180" /> Mon-Sat: Opening at 10:00 AM
                </div>
            </section>
    )
}
