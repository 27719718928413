import React from 'react'

export default function Footer() {
    return (
        <footer id="footer">
            <div className="container">
                <div className="social-links">
                    <a href="https://twitter.com/happifyindia" target="_blank" without rel="noreferrer" className="twitter"><i className="bx bxl-twitter" /></a>
                    <a href="https://www.facebook.com/Happifylndia/" target="_blank" without rel="noreferrer" className="facebook"><i className="bx bxl-facebook" /></a>
                    <a href="https://www.instagram.com/happifyindia/" target="_blank" without rel="noreferrer" className="instagram"><i className="bx bxl-instagram" /></a>
                    <a href="https://www.kooapp.com/profile/happifyindia" target="_blank" without rel="noreferrer" className="google-plus"><img src="assets/img/koo.png" alt=""/></a>
                    <a href="https://www.linkedin.com/company/happify-india" target="_blank" without rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin" /></a>
                </div>
                <div className="copyright">© 2021 <strong><span>happifyindia</span></strong></div>
                <div className="credits">All Rights Reserved</div>
            </div>
        </footer>

    )
}
